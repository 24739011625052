import React, { useEffect, useState } from 'react';

import styles from './NumberInput.module.css';
interface NumberInputProperties {
	className?: string;
	disabled?: boolean;
	max?: number;
	min?: number;
	setValue: (value: (previous: number) => number) => void;
	value: number;
}

const NumberInput: React.FC<NumberInputProperties> = ({
	className,
	disabled,
	max = Infinity,
	min = 0,
	setValue,
	value,
}) => {
	const [lessBeingClicked, setLessBeingClicked] = useState(false);
	const [moreBeingClicked, setMoreBeingClicked] = useState(false);
	const [internalValue, setInternalValue] = useState(value);

	useEffect(() => {
		let interval: NodeJS.Timeout | undefined;
		const timeout = setTimeout(() => {
			if (lessBeingClicked) {
				interval = setInterval(() => {
					setInternalValue(previousValue => previousValue - 1);
				}, 200);
			} else if (moreBeingClicked) {
				interval = setInterval(() => {
					setInternalValue(previousValue => previousValue + 1);
				}, 200);
			} else {
				setValue(() => internalValue);
			}
		}, 500);

		return () => {
			clearInterval(interval);
			clearTimeout(timeout);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lessBeingClicked, moreBeingClicked, setValue]);

	return (
		<div className={`${styles.container} ${className}`}>
			<button
				className={styles.button}
				disabled={value === min || disabled}
				onClick={event => {
					setValue(previousValue => previousValue - 1);
					event.stopPropagation();
					event.preventDefault();
				}}
				onMouseDown={() => setLessBeingClicked(true)}
				onMouseLeave={() => setLessBeingClicked(false)}
				onMouseUp={() => setLessBeingClicked(false)}
			>
				-
			</button>
			<input
				className={styles.input}
				disabled={disabled}
				onChange={event =>
					setValue(() => Number.parseInt(event.target.value.replaceAll(/\D/g, ''), 10) || min)
				}
				onClick={event => {
					event.stopPropagation();
					event.preventDefault();
				}}
				onKeyDown={event => {
					if (!/^\d*$/.test(event.key)) {
						event.preventDefault();
					}
				}}
				type="text"
				value={internalValue}
			/>
			<button
				className={styles.button}
				disabled={value === max || disabled}
				onClick={event => {
					setValue(previousValue => previousValue + 1);
					event.stopPropagation();
					event.preventDefault();
				}}
				onMouseDown={() => setMoreBeingClicked(true)}
				onMouseLeave={() => setMoreBeingClicked(false)}
				onMouseUp={() => setMoreBeingClicked(false)}
			>
				+
			</button>
		</div>
	);
};

export default NumberInput;
