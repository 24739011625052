import { createFileRoute } from '@tanstack/react-router';

import { useCacheStore } from '@/stores';

import { getItems } from '@/utils/Item.ts';

const PRODUCTS_PER_PAGE = 10;

export interface SearchParameters {
	[key: string]: number | string | undefined;

	p: number;
	q: string;
}

export const Route = createFileRoute('/search/_search/')({
	loader: ({
		location: {
			// @ts-expect-error fix this
			search: { p, ...rest },
		},
	}) => {
		let searchKey = '';
		for (const [key, value] of Object.entries(rest)) {
			searchKey += `${key}=${value}&`;
		}
		searchKey = searchKey.slice(0, -1);
		const { cache, fetching } = useCacheStore.getState().searchCache[searchKey] ?? {};

		if (!cache?.[p] && !fetching?.includes(p)) {
			useCacheStore.getState().setFetching(searchKey, p);
			getItems({
				...rest,
				fieldset: 'search',
				include: Object.keys(cache ?? {}).length === 0 ? 'facets' : undefined,
				limit: PRODUCTS_PER_PAGE,
				offset: (p - 1) * PRODUCTS_PER_PAGE,
				sort: 'relevance:desc',
			}).then(response => {
				useCacheStore
					.getState()
					.setSearchCache(searchKey, p, response.items, response.total, response.facets);
			});
		}
	},
	validateSearch: (search: Record<string, string>) => {
		const page = Number.parseInt(search.p ?? '1', 10);
		const parsedSearch: Record<string, string | undefined> = {};
		for (const key in search) {
			parsedSearch[key] = search[key] === '' ? undefined : search[key];
		}

		const result: SearchParameters = {
			...parsedSearch,
			p: Number.isNaN(page) ? 1 : page,
			q: search.q ?? '',
		};
		return result;
	},
});
