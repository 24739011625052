import { Link } from '@tanstack/react-router';
import React, { useMemo, useState } from 'react';

import { MenuItem } from '@/customTypes/environment';

import styles from './MenuElement.module.css';

const MenuElement: React.FC<{ item: MenuItem; onClick?: () => void }> = ({ item, onClick }) => {
	const [mouseInside, setMouseInside] = useState(false);
	// TODO remove this, some categories in tko have a ▼ at the end
	const text = useMemo(() => item.text.replace(/▼$/, ''), [item]);

	if (!item.children) {
		return (
			<li className={styles.catLevel1}>
				<Link
					className={styles.link}
					onClick={() => {
						onClick?.();
					}}
					params={{
						categoryURL: item.href.replaceAll('/', ''),
					}}
					search={{
						p: 1,
					}}
					to="/category/$categoryURL"
				>
					{text}
				</Link>
			</li>
		);
	}

	return (
		<li
			className={styles.catLevel1}
			onMouseEnter={() => setMouseInside(true)}
			onMouseLeave={() => setMouseInside(false)}
		>
			<Link
				className={styles.link}
				onClick={() => {
					onClick?.();
					setMouseInside(false);
				}}
				params={{
					categoryURL: item.href.replaceAll('/', ''),
				}}
				search={{
					p: 1,
				}}
				to="/category/$categoryURL"
			>
				{text}
			</Link>
			{mouseInside ? (
				<ul className={`${styles.children} ${item.level === '2' ? styles.level2 : ''}`}>
					{item.children.map((child, index) => (
						<MenuElement
							item={child}
							key={index}
							onClick={() => {
								setMouseInside(false);
								onClick?.();
							}}
						/>
					))}
				</ul>
			) : null}
		</li>
	);
};

export default MenuElement;
