import { QueryClientProvider } from '@tanstack/react-query';
import React from 'react';

import queryClient from '@/stores/QueryClient.ts';

const QueryProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => (
	<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);

export default QueryProvider;
