import { customFetch } from '@/utils/network';
import { createStore, setter } from '@/utils/store';

import { SimplifiedItem } from '@/customTypes/Item';
import type Profile from '@/customTypes/Profile';

export interface ProfileStore {
	addToLastViewed: (item: SimplifiedItem) => void;
	lastViewed: SimplifiedItem[];
	loading: boolean;
	login: (email: string, password: string, token?: string) => Promise<void>;
	profile: Profile;
	setLoading: (loading: boolean) => void;
	setProfile: (profile: Partial<Profile>) => void;
}

const emptyProfile: Profile = {
	altphone: undefined,
	balance: 0,
	balance_available: 0,
	balance_available_formatted: '',
	balance_formatted: '',
	campaignsubscriptions: [],
	companyname: '',
	consentlastupdate: 0,
	creditcards: [],
	creditholdoverride: '',
	creditlimit: 0,
	creditlimit_formatted: '',
	currency: {
		code: '',
		currencyname: '',
		internalid: '',
		precision: 0,
		symbol: '',
	},
	customer: false,
	email: '',
	emailsubscribe: '',
	fax: undefined,
	firstname: '',
	internalid: '',
	isGuest: '',
	isLoggedIn: 'F',
	isperson: false,
	language: '',
	lastname: '',
	middlename: '',
	name: '',
	paymentmethods: [],
	phone: '',
	phoneinfo: {
		altphone: undefined,
		fax: undefined,
		phone: '',
	},
	priceLevel: '',
	stage: '',
	subsidiary: '',
	type: '',
};

export default createStore<ProfileStore>((set, get) => {
	customFetch<Profile>({
		url: '/services/Profile.Service.ss',
	}).then(profile => set({ loading: false, profile }));

	return {
		addToLastViewed: (item: SimplifiedItem) => {
			let items = JSON.parse(localStorage.getItem('last-viewed') ?? '[]') as SimplifiedItem[];
			items = items.filter(currentItem => currentItem.url !== item.url);
			if (items.length > 5) {
				items.pop();
			}
			items.unshift({
				customFields: item.customFields,
				imagesDetail: item.imagesDetail,
				internalId: item.internalId,
				itemId: item.itemId,
				name: item.name,
				priceFormated: item.priceFormated,
				url: item.url,
			});
			localStorage.setItem('last-viewed', JSON.stringify(items));
			set({ lastViewed: items });
		},
		lastViewed: JSON.parse(localStorage.getItem('last-viewed') ?? '[]') as SimplifiedItem[],
		loading: true,
		login: async (email, password, token) => {
			set({ loading: true });
			await customFetch<Profile>({
				body: {
					email,
					'grecaptcha-token': token,
					password,
					redirect: 'true',
				},
				method: 'POST',
				url: '/services/Account.Login.Service.ss',
			})
				.then(response => {
					set({ loading: false, profile: response });
				})
				.catch(error => {
					set({ loading: false, profile: emptyProfile });
					throw error;
				});
		},
		profile: emptyProfile,
		setLoading: loading => set({ loading }),
		setProfile: data => {
			set({
				profile: setter(data, get().profile),
			});
		},
	};
});
