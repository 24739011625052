import { IconLoader2 } from '@tabler/icons-react';
import { motion, useAnimation } from 'framer-motion';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { getAllImages } from '@/utils/Item';

import { SimplifiedItem } from '@/customTypes/Item';

import styles from './ImageShower.module.css';

const ImageShower: React.FC<{
	className?: string;
	item?: SimplifiedItem;
	mouseInside: boolean;
}> = ({ className, item, mouseInside }) => {
	const [mouseEntered, setMouseEntered] = useState(false);
	const imageInterval = 1000;
	const images = useMemo(() => getAllImages(2, item), [item]);
	const controls = useAnimation();
	const progressControls = useAnimation();
	const index = useRef(0);

	// Handle mouse enter and leave from parent
	useEffect(() => {
		if (mouseInside) {
			setMouseEntered(true);
			if (images.length > 1) {
				progressControls.start({
					width: `${(1 / (images.length - 1)) * 100}%`,
				});
			}
		} else {
			index.current = 0;
			controls.start({
				translateX: 0,
			});
			progressControls.start({
				transition: {
					duration: 0,
				},
				width: 0,
			});
		}
	}, [mouseInside, images, controls, progressControls]);

	// Change from one image to the next while hovered
	useEffect(() => {
		if (mouseInside) {
			const interval = setInterval(() => {
				if (index.current < images.length - 1) {
					index.current++;
					controls.start({
						translateX: `-${index.current}00%`,
					});
					progressControls.start({
						width: `${((index.current + 1) / (images.length - 1)) * 100}%`,
					});
				}
			}, imageInterval);

			return () => clearInterval(interval);
		}
	}, [controls, images, mouseInside, progressControls]);

	return (
		<div
			className={`${className ?? ''} ${mouseInside ? styles.hover : ''}`}
			style={{ overflow: 'hidden', position: 'relative' }}
		>
			<motion.div
				animate={controls}
				className={styles.container}
				transition={{
					ease: 'easeInOut',
				}}
			>
				{item ? null : <IconLoader2 className={`${styles.loaderIcon} loader`} />}
				{images.slice(0, mouseEntered ? images.length : 1).map((image, index) => (
					<img alt={image} className={styles.image} key={index} src={image} />
				))}
			</motion.div>
			<div
				className={`${styles.progressBarContainer} ${mouseInside && images.length > 1 ? styles.hover : ''}`}
			>
				<motion.div
					animate={progressControls}
					className={styles.progressBar}
					initial={{ width: '0%' }}
					style={{ width: '0%' }}
					transition={{ delay: 0, duration: imageInterval / 1000 }}
				/>
			</div>
		</div>
	);
};

export default ImageShower;
