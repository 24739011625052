import { Link } from '@tanstack/react-router';
import { motion } from 'framer-motion';
import React, { useEffect, useMemo, useRef } from 'react';

import { useConfigStore } from '@/stores';

import { buildMenuTree } from '@/utils/functions.ts';

import styles from './Menu.module.css';
import MenuElement from './MenuElement';

const Menu: React.FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => {
	const navigationData = useConfigStore(store => store.config.navigationData);
	const nodes = useMemo(() => buildMenuTree(navigationData), [navigationData]);
	const containerReference = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (containerReference.current && isOpen) {
			containerReference.current.focus();
		}
	}, [isOpen]);

	return (
		<motion.nav
			animate={{
				x: isOpen ? '0%' : '-100%',
			}}
			className={styles.container}
			initial={{ x: '-100%' }}
			onBlur={event => {
				if (
					!containerReference.current?.contains(event.relatedTarget) &&
					event.relatedTarget?.id !== 'menu-button'
				) {
					onClose();
				}
			}}
			ref={containerReference}
			role="menu"
			tabIndex={-1}
			transition={{
				duration: 0.2,
				ease: 'easeInOut',
			}}
		>
			<div>
				<Link onClick={() => onClose()} params={{}} search={{}} to="/login">
					Login/Register
				</Link>
				{nodes.map(item => (
					<MenuElement item={item} key={item.id + item.text} />
				))}
			</div>
		</motion.nav>
	);
};

export default Menu;
