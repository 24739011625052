import { IconChevronRight } from '@tabler/icons-react';
import { domAnimation, LazyMotion, m } from 'framer-motion';
import React from 'react';

import styles from './Collapse.module.css';

const Collapse: React.FC<
	{ defaultOpen?: boolean; title: string } & React.HTMLAttributes<HTMLDivElement>
> = ({ children, defaultOpen, title, ...rest }) => {
	const [open, setOpen] = React.useState(defaultOpen);

	return (
		<LazyMotion features={domAnimation} strict>
			<div aria-expanded={open} {...rest}>
				<button className={styles.button} onClick={() => setOpen(!open)}>
					<span>{title}</span>
					<m.div
						animate={{ rotate: open ? 90 : 0 }}
						transition={{
							duration: 0.2,
							ease: 'easeInOut',
						}}
					>
						<IconChevronRight />
					</m.div>
				</button>
				<m.div
					animate={{
						height: open ? 'auto' : 0,
						transition: {
							duration: 0.2,
							ease: 'easeInOut',
						},
					}}
					initial={{
						height: open ? 'auto' : 0,
					}}
					style={{ overflow: 'hidden' }}
				>
					{children}
				</m.div>
			</div>
		</LazyMotion>
	);
};

export default Collapse;
