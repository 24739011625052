import { create } from 'zustand';

export type SetterParameter<T> = ((previous: T) => Partial<T>) | Partial<T>;

export const setter = <T>(set: SetterParameter<T>, previous: T): T => ({
	...previous,
	...(typeof set === 'function' ? set(previous) : set),
});

export const createStore = <Store extends object>(
	createFunction: (set: (function_: SetterParameter<Store>) => void, get: () => Store) => Store,
) => create<Store>(createFunction);
