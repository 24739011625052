import React from 'react';

import Stars from '@/components/Stars/Stars.tsx';

import styles from './Reviews.module.css';

type StarRatings = Record<number, number>;

const Reviews: React.FC = () => {
	const totalReviews = 48;
	const starRatings: StarRatings = {
		1: 3,
		2: 2,
		3: 5,
		4: 13,
		5: 25,
	};

	const averageRating = (
		Object.keys(starRatings).reduce(
			(accumulator, key) => accumulator + starRatings[Number.parseInt(key)] * Number.parseInt(key),
			0,
		) / totalReviews
	).toFixed(1);

	return (
		<div
			style={{
				border: '1px solid #ddd',
				display: 'flex',
				justifyContent: 'space-between',
				padding: '16px',
			}}
		>
			<div style={{ textAlign: 'center' }}>
				<div style={{ fontSize: '32px', fontWeight: 'bold' }}>{averageRating}</div>
				<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '8px' }}>
					{Array.from({ length: 5 }).map((_, index) => (
						<span
							key={index}
							style={{ color: index < Math.round(Number(averageRating)) ? '#FFD700' : '#ddd' }}
						>
							★
						</span>
					))}
				</div>
				<div>{totalReviews} Reviews</div>
				<button className={styles.write}>Write a Review</button>
			</div>
			<div style={{ width: '50%' }}>
				{Object.keys(starRatings).map((star, index) => (
					<div key={index} style={{ alignItems: 'center', display: 'flex', marginBottom: '4px' }}>
						<Stars className={styles.stars} value={Number.parseInt(star)} />
						<div className={styles.slider}>
							<span
								style={{ width: `${(starRatings[Number.parseInt(star)] / totalReviews) * 100}%` }}
							/>
						</div>
						<span style={{ marginLeft: '8px', width: '30px' }}>
							{starRatings[Number.parseInt(star)]}
						</span>
					</div>
				))}
			</div>
		</div>
	);
};

export default Reviews;
