import {
	IconLoader2,
	IconLogin2,
	IconShoppingBag,
	IconUserCircle,
	IconX,
} from '@tabler/icons-react';
import { Link } from '@tanstack/react-router';
import { motion } from 'framer-motion';
import { useMemo, useState } from 'react';

import { Menu } from '@/components';
import MenuElement from '@/components/Menu/MenuElement.tsx';
import { MenuToggle } from '@/components/MenuToggle/MenuToggle.tsx';
import MiniCart from '@/components/MiniCart/MiniCart';
import SearchBar from '@/components/SearchBar/SearchBar';

import { useConfigStore, useOrderStore, useProfileStore } from '@/stores';

import { buildMenuTree } from '@/utils/functions.ts';

import styles from './Header.module.css';

const Header = () => {
	// TODO get the value from search param using useGlobalSearchParams
	const lineCount = useOrderStore(store => store.order.lines.length);
	const loadingCart = useOrderStore(store => store.loading);
	const isLoggedIn = useProfileStore(store => store.profile.isLoggedIn === 'T');
	const loadingProfile = useProfileStore(store => store.loading);
	const navigationData = useConfigStore(store => store.config.navigationData);
	const [menuOpen, setMenuOpen] = useState(false);
	const [cartOpen, setCartOpen] = useState(false);
	const nodes = useMemo(() => buildMenuTree(navigationData), [navigationData]);

	return (
		<header>
			<div className={styles.container}>
				<div className="containerGrid">
					<div className={styles.containerWrapper}>
						<MenuToggle
							className={styles.menuToggle}
							id="menu-button"
							isOpen={menuOpen}
							toggle={() => setMenuOpen(wasOpen => !wasOpen)}
						/>
						<Link className={styles.logo} params={{}} search={{}} to="/">
							<img alt="logo" src="/logo-color.svg" />
						</Link>
						<SearchBar className={styles.searchBarDesktop} />
						<div style={{ alignItems: 'center', display: 'flex' }}>
							<Link
								className={styles.profile}
								disabled={loadingProfile}
								params={{}}
								search={{}}
								to={isLoggedIn ? '/' : '/login'}
							>
								Login
								{loadingProfile ? (
									<IconLoader2 className="loader" />
								) : isLoggedIn ? (
									<IconLogin2 />
								) : (
									<IconUserCircle />
								)}
							</Link>
							<button
								className={styles.cartButton}
								disabled={loadingCart}
								onClick={() => {
									setCartOpen(wasOpen => !wasOpen);
								}}
							>
								{cartOpen ? <IconX /> : <IconShoppingBag />}
								{loadingCart ? <IconLoader2 className="loader" /> : <label>{lineCount}</label>}
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className={styles.searchBarMobile}>
				<SearchBar />
			</div>
			<div className={styles.headerWrapper}>
				<div className="containerGrid">
					<ul className={styles.facetsContainer}>
						{nodes.map(item => (
							<MenuElement item={item} key={item.id} />
						))}
					</ul>
				</div>
			</div>
			<motion.nav
				animate={{
					translateX: cartOpen ? '0%' : '100%',
				}}
				className={styles.cartContainer}
				initial={{ translateX: '100%' }}
				transition={{
					duration: 0.2,
					type: 'just',
				}}
			>
				<MiniCart />
			</motion.nav>
			<Menu isOpen={menuOpen} onClose={() => setMenuOpen(false)} />
		</header>
	);
};

export default Header;
