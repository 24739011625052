import { createFileRoute } from '@tanstack/react-router';

import { useCacheStore } from '@/stores';

import { getItems } from '@/utils/Item.ts';

const PRODUCTS_PER_PAGE = 10;

export const Route = createFileRoute('/category/$categoryURL')({
	loader: ({
		location: {
			// @ts-expect-error fix this
			search: { p },
		},
		params: { categoryURL },
	}) => {
		const searchKey = 'commercecategory=' + categoryURL;
		const { cache, fetching } = useCacheStore.getState().searchCache[searchKey] ?? {};
		// TODO we can merge part of this code with search page
		if (!cache?.[p] && !fetching?.includes(p)) {
			useCacheStore.getState().setFetching(searchKey, p);
			// TODO we may be able to do this using react query, not sure
			getItems({
				commercecategoryurl: '/' + categoryURL,
				fieldset: 'search',
				include: Object.keys(cache ?? {}).length === 0 ? 'facets' : undefined,
				limit: PRODUCTS_PER_PAGE,
				offset: (p - 1) * PRODUCTS_PER_PAGE,
				sort: 'relevance:desc',
			}).then(response => {
				useCacheStore
					.getState()
					.setSearchCache(searchKey, p, response.items, response.total, response.facets);
			});
		}
	},
	validateSearch: (search: Record<string, string>) => {
		const page = Number.parseInt(search.p ?? '1', 10);

		return {
			p: Number.isNaN(page) ? 1 : page,
		};
	},
});
