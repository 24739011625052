import { parseItem } from '@/utils/Item.ts';
import { customFetch } from '@/utils/network';
import { createStore, setter } from '@/utils/store';

import { Line, Order } from '@/customTypes/Order';

export interface OrderStore {
	addLines: (
		lines: { item: { internalid: number; itemtype: string }; quantity: number }[],
	) => Promise<Order['lines']>;
	loading: boolean;
	order: Order;
	removeLine: (internalid: string) => Promise<void>;
	setLoading: (loading: boolean) => void;
	setOrder: (order: Partial<Order>) => void;
	updateLine: (lineId: string, updater: (line: Line) => Line) => Promise<void>;
}

const initialOrder: Order = {
	addresses: [],
	agreetermcondition: false,
	billaddress: undefined,
	ismultishipto: false,
	isPaypalComplete: false,
	latest_addition: '',
	lines: [],
	paymentmethods: [],
	promocodes: [],
	shipaddress: '',
	shipmethod: undefined,
	shipmethods: [],
	summary: {
		discountedsubtotal: 0,
		discountedsubtotal_formatted: '$0.00',
		discountrate: 0,
		discountrate_formatted: '$0.00',
		discounttotal: 0,
		discounttotal_formatted: '$0.00',
		estimatedshipping: 0,
		estimatedshipping_formatted: '$0.00',
		giftcertapplied: 0,
		giftcertapplied_formatted: '$0.00',
		handlingcost: 0,
		handlingcost_formatted: '$0.00',
		itemcount: 0,
		shippingcost: 0,
		shippingcost_formatted: '$0.00',
		subtotal: 0,
		subtotal_formatted: '$0.00',
		tax2total: 0,
		tax2total_formatted: '$0.00',
		taxondiscount: 0,
		taxondiscount_formatted: '$0.00',
		taxonhandling: 0,
		taxonhandling_formatted: '$0.00',
		taxonshipping: 0,
		taxonshipping_formatted: '$0.00',
		taxtotal: 0,
		taxtotal_formatted: '$0.00',
		total: 0,
		total_formatted: '$0.00',
		totalcombinedtaxes: 0,
		totalcombinedtaxes_formatted: '$0.00',
	},
};

const parseOrder = (order: Order): Order => {
	for (const line of order.lines) {
		// @ts-expect-error
		line.item = parseItem(line.item);
	}
	return order;
};

/**
 * TODO optimistic updates, when we add to carte we automatically add the line
 * If the request success it should contain the same as the fake added line
 * If it fails we rollback to the previous state
 */
export default createStore<OrderStore>((set, get) => {
	// Initial fetch of the order
	customFetch<Order>({
		url: '/services/LiveOrder.Service.ss',
	}).then(order => set({ loading: false, order: parseOrder(order) }));

	return {
		addLines: async lines => {
			set({ loading: true });

			return customFetch<Order>({
				body: lines,
				method: 'POST',
				url: '/services/LiveOrder.Line.Service.ss',
			}).then(order => {
				set({ loading: false, order: parseOrder(order) });

				return order.lines.filter(line =>
					order.latest_addition.split(',').includes(line.internalid),
				);
			});
		},
		loading: true,
		order: initialOrder,
		removeLine: async internalid => {
			const previousOrder = get().order;
			set({
				loading: true,
				order: {
					...previousOrder,
					lines: get().order.lines.filter(line => line.internalid !== internalid),
				},
			});

			return customFetch<Order>({
				method: 'DELETE',
				parameters: {
					internalid,
				},
				url: '/services/LiveOrder.Line.Service.ss',
			})
				.then(order => {
					set({ loading: false, order: parseOrder(order) });
				})
				.catch(error => {
					set({ loading: false, order: previousOrder });
					throw error;
				});
		},
		setLoading: loading => set({ loading }),
		setOrder: data => {
			set({
				order: setter(data, get().order),
			});
		},
		updateLine: async (lineId, updater) => {
			// TODO optimistic updates
			const previousOrder = get().order;
			const line = get().order.lines.find(line => line.internalid === lineId) ?? ({} as Line);
			const newLine = updater(line);
			if (newLine === line) {
				return;
			}

			set({ loading: true });
			return customFetch<Order>({
				body: newLine,
				method: 'PUT',
				parameters: {
					internalid: lineId,
				},
				url: '/services/LiveOrder.Line.Service.ss',
			})
				.then(order => {
					set({ loading: false, order: parseOrder(order) });
				})
				.catch(error => {
					set({ loading: false, order: previousOrder });
					throw error;
				});
		},
	};
});
