import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { motion, MotionProps, useAnimation } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';

interface CarouselProperties {
	children: React.ReactNode[];
	className?: string;
	currentPosition?: number;
	hideArrows?: boolean;
	setCurrentPosition?: (position: number) => void;
}

import styles from './Carousel.module.css';

const Carousel: React.FC<CarouselProperties & MotionProps> = ({
	children,
	className,
	currentPosition,
	hideArrows,
	setCurrentPosition,
	...rest
}) => {
	const carouselReference = useRef<HTMLDivElement>(null);
	const [currentIndex, setCurrentIndex] = useState(currentPosition ?? 0); // Track the current index
	const controls = useAnimation();
	const currentX = useRef(0);
	const [width, setWidth] = useState(0);

	// Handle screen resize
	useEffect(() => {
		const resizeObserver = new ResizeObserver(entries => {
			if (entries[0]) {
				setWidth(Math.floor(entries[0].contentRect.width));
			}
		});

		if (carouselReference.current) {
			resizeObserver.observe(carouselReference.current);
		}

		return () => {
			resizeObserver.disconnect();
		};
	}, []);

	// Handle changes in position from the parent
	useEffect(() => {
		if (currentPosition !== undefined) {
			setCurrentIndex(currentPosition);
			controls.start({
				x: -currentPosition * width,
			});
		}
	}, [controls, currentPosition, width]);

	return (
		<motion.div
			{...rest}
			className={`${className ?? ''} ${styles.carousel}`}
			ref={carouselReference}
		>
			<motion.div
				animate={controls}
				className={styles.innerCarousel}
				drag="x"
				dragConstraints={{ left: -width * (children.length - 1), right: 0 }}
				onDragEnd={() => {
					const width = carouselReference.current?.clientWidth ?? 0;
					const newIndex = Math.min(
						Math.max(Math.round(currentX.current / width), -children.length + 1),
						0,
					);
					setCurrentIndex(-newIndex);
					setCurrentPosition?.(-newIndex);
					controls.start({
						x: newIndex * width,
					});
				}}
				onUpdate={event => {
					currentX.current = Number(event.x);
				}}
				transition={{ duration: 0.2, ease: 'easeInOut' }}
			>
				{children}
			</motion.div>
			{children.length > 1 && !hideArrows ? (
				<>
					<button
						className={styles.previous}
						onClick={() => {
							const width = carouselReference.current?.clientWidth ?? 0;
							const newIndex = currentIndex === 0 ? children.length - 1 : currentIndex - 1;
							setCurrentPosition?.(newIndex);
							setCurrentIndex(newIndex);
							controls.start({
								x: -newIndex * width,
							});
						}}
					>
						<IconChevronLeft size={40} />
					</button>
					<button
						className={styles.next}
						onClick={() => {
							const width = carouselReference.current?.clientWidth ?? 0;
							const newIndex = currentIndex === children.length - 1 ? 0 : currentIndex + 1;
							setCurrentIndex(newIndex);
							setCurrentPosition?.(newIndex);
							controls.start({
								x: -newIndex * width,
							});
						}}
					>
						<IconChevronRight size={40} />
					</button>
				</>
			) : null}
		</motion.div>
	);
};

export default Carousel;
