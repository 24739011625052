import type { Features } from './features.d';
import type { ItemResponseItem, ItemResponseSimplifiedItem } from '../customTypes/itemResponse';

const features: Features = {
	development: {
		enableMillion: false,
	},
	network: {
		preloadDelay: 300,
		proxyURL: 'scact.awalabs.site',
		ssp: '/scs',
	},
	parseItem: (item: ItemResponseItem) => ({
		description: item.storedetaileddescription,
		imagesDetail: item.itemimages_detail,
		internalId: item.internalid,
		itemId: item.itemid,
		name: item.storedisplayname2,
		price: item.onlinecustomerprice_detail?.onlinecustomerprice,
		priceFormated: item.onlinecustomerprice_detail?.onlinecustomerprice_formatted,
		type: item.itemtype,
		url: item.urlcomponent,
	}),
	parseSimplifiedItem: (item: ItemResponseSimplifiedItem) => ({
		imagesDetail: item.itemimages_detail,
		internalId: item.internalid,
		itemId: item.itemid,
		name: item.displayname,
		priceFormated: item.onlinecustomerprice_detail?.onlinecustomerprice_formatted,
		url: item.urlcomponent,
	}),
	pwaOptions: undefined,
	search: {
		infiniteScroll: true,
		searchSpring: true,
	},
	services: {
		builderIO: {
			apiKey: 'b5413c2ba9e74a0b84e2a6acfc884243',
		},
		captcha: {
			siteKey: '6LcVhiApAAAAAA_qLZH_-LsqABnobMHuvj6PIAo0',
		},
	},
	vendorName: 'AWA Commerce',
};

export default features;
