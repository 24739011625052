import * as React from 'react';

import Carousel from '@/components/Carousel/Carousel';
import ItemComponent from '@/components/ItemComponent/ItemComponent';

import { Item } from '@/customTypes/Item';

import styles from './ItemCarousel.module.css';

interface ItemCarouselProperties {
	className?: string;
	items?: Item[];
	loadingAmmount?: number;
}

const ItemCarousel: React.FC<ItemCarouselProperties> = ({ className, items, loadingAmmount }) => (
	<Carousel className={className}>
		{items
			? items.map((item, index) => (
					<ItemComponent className={styles.itemContainer} item={item} key={index} />
				))
			: Array.from({ length: loadingAmmount ?? 0 }).map((_, index) => (
					<ItemComponent className={styles.itemContainer} item={undefined} key={index} />
				))}
	</Carousel>
);

export default ItemCarousel;
