import { customFetch } from '@/utils/network';

import { Item, RangeFacetType, SelectFacetType, SimplifiedItem } from '@/customTypes/Item';
import {
	ItemRequestOptions,
	ItemResponseItem,
	ItemResponseSimplifiedItem,
} from '@/customTypes/itemResponse';

import features from '@/vendor/features.ts';

interface ItemResponse {
	facets?: (RangeFacetType | SelectFacetType)[];
	items: ItemResponseItem[];
	total: number;
}

interface ParsedItemResponse {
	facets?: (RangeFacetType | SelectFacetType)[];
	items: Item[];
	total: number;
}

export const parseItem = (item: ItemResponseItem): Item => {
	const customFields = {};
	for (const key in Object.keys(item)) {
		if (key.startsWith('custitem_')) {
			// @ts-expect-error
			customFields[key.replace('custitem_', '')] = item[key];
			// @ts-expect-error
			delete item[key];
		}
	}
	return {
		...features.parseItem(item),
		correlatedItemsDetail: item.correlateditems_detail?.map(parseSimplifiedItem),
		customFields,
	};
};

export const parseSimplifiedItem = (item: ItemResponseSimplifiedItem): SimplifiedItem => {
	const customFields = {};
	for (const key in Object.keys(item)) {
		if (key.startsWith('custitem_')) {
			// @ts-expect-error
			customFields[key.replace('custitem_', '')] = item[key];
			// @ts-expect-error
			delete item[key];
		}
	}
	return {
		...features.parseSimplifiedItem(item),
		customFields,
	};
};

export const getItemsSS = async (options: object, signal?: AbortSignal): Promise<ItemResponse> =>
	customFetch({
		parameters: {
			c: '4902918_SB1',
			consultLevel: 0,
			country: 'US',
			custitem_is_nhc_item: true,
			custitem_nhc_hide_from_website: false,
			domain: 'nhc-sb.awalabs.site/',
			facet: {
				exclude: ['custitem_nhc_hide_from_website', 'custitem_is_nhc_item'],
			},
			isloggedin: false,
			language: 'en',
			model: 'itemColl',
			n: 3,
			postman: true,
			pricelevel: 9,
			priceLevelIds: {
				employee: 4,
				guest: {
					list: 8,
					selling: 7,
				},
				member: {
					list: 10,
					selling: 9,
				},
				retail: {
					list: 12,
					selling: 11,
				},
			},
			q: 'healthy',
			ss_sessionId: '6f19d2a1-cf7c-4e61-91bf-dc98be2a9e79',
			ss_siteId: 'th10wm',
			ss_userId: 'martin123@awalabs.com',
			use_pcv: 'T',
			...options,
		},
		signal,
		url: '/extensions/RenewCo%20-%2021-1%20-%20NHC/SearchSpring/1.0.0/services/search.Service.ss',
	});

/**
 * Fetch items from the SuiteCommerce item API.
 * @param options - The options to pass to the API
 * @param signal - An optional signal to abort the request
 * @returns A promise that resolves to the item response
 */
export const getItems = async (
	options: ItemRequestOptions,
	signal?: AbortSignal,
): Promise<ParsedItemResponse> =>
	customFetch<ItemResponse>({
		dontAddSSP: true,
		parameters: options,
		signal,
		url: '/api/items',
	}).then(response => ({
		...response,
		items: response.items.map(item => parseItem(item)),
	}));

/**
 * Get all images from an item, including the main image and any additional images.
 * @param size - The size id to resize image
 * @param item - The item to get the images from
 * @returns An array of image URLs
 */
// @ts-expect-error TODO use param again
export const getAllImages = (size: number, item?: Item | SimplifiedItem) => {
	const images: string[] = [];
	try {
		for (const key of Object.keys(item?.imagesDetail ?? {})) {
			// @ts-expect-error we unsafely get the key
			const value = item?.imagesDetail[key];
			if (value.url) {
				images.push(value.url);
			}
			if (Array.isArray(value)) {
				for (const image of value) {
					if (image.url) {
						images.push(image.url);
					}
				}
			}
			if (value.urls) {
				for (const image of value.urls) {
					if (image.url) {
						images.push(image.url);
					}
				}
			}
		}
	} catch (error) {
		console.log(error);
		console.log(item);
		return [];
	}

	return images.filter((_, index) => images.indexOf(images[index]) === index);
};
