import * as React from 'react';

import ItemCarousel from '@/components/ItemCarousel/ItemCarousel.tsx';

import { useProfileStore } from '@/stores';

import { Item } from '@/customTypes/Item';

const LastViewed: React.FC = () => {
	const lastViewed = useProfileStore(store => store.lastViewed);

	return <ItemCarousel items={lastViewed as Item[]} />;
};

export default LastViewed;
