import { createStore } from '@/utils/store';

import { Item, RangeFacetType, SelectFacetType, SimplifiedItem } from '@/customTypes/Item';

interface SearchCache {
	pdpSimplifiedItem?: SimplifiedItem;
	searchCache: Record<
		string,
		{
			cache: Record<number, Item[]>;
			facets?: (RangeFacetType | SelectFacetType)[];
			fetching: number[];
			total: number;
		}
	>;
	setFetching: (searchKey: string, page: number) => void;
	setPdpSimplifiedItem: (item: SimplifiedItem) => void;
	setSearchCache: (
		searchKey: string,
		page: number,
		items: Item[],
		total: number,
		facets?: (RangeFacetType | SelectFacetType)[],
	) => void;
}

export default createStore<SearchCache>((set, get) => ({
	searchCache: {},
	setFetching: (searchKey, page) => {
		set({
			searchCache: {
				...get().searchCache,
				[searchKey]: {
					...get().searchCache[searchKey],
					fetching: [...(get().searchCache[searchKey]?.fetching ?? []), page],
				},
			},
		});
	},
	setPdpSimplifiedItem: item => {
		set({
			pdpSimplifiedItem: item,
		});
	},
	setSearchCache: (searchKey, page, items, total, facets) => {
		set({
			searchCache: {
				...get().searchCache,
				[searchKey]: {
					cache: {
						...get().searchCache[searchKey]?.cache,
						[page]: items,
					},
					facets: facets ?? get().searchCache[searchKey]?.facets,
					fetching: get().searchCache[searchKey]?.fetching.filter(item => item !== page),
					total: total,
				},
			},
		});
	},
}));
