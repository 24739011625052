import type { MenuItem } from '@/customTypes/environment';

export type DeepPartial<T> = {
	[P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

export const deepMerge = <T extends object>(object1: T, object2: T): T => {
	if (typeof object1 !== 'object' || typeof object2 !== 'object') return object2;

	const result: any = {};

	for (const key in object1) {
		result[key] = object1[key];
	}

	for (const key in object2) {
		if (object2[key] !== null && typeof object2[key] === 'object') {
			result[key] =
				key in object1 && object1[key] !== null
					? // @ts-expect-error ts unsafe function
						deepMerge(object1[key], object2[key])
					: object2[key];
		} else {
			result[key] = object2[key];
		}
	}

	return result;
};

export const buildMenuTree = (navigationData: MenuItem[]) => {
	const nodes3 = navigationData.filter(item => item.level === '3');
	const nodes2 = navigationData.filter(item => item.level === '2');
	const nodes1 = navigationData.filter(item => item.level === '1');
	for (const node3 of nodes3) {
		const parent = nodes2.find(item => item.id === node3.parentId);
		if (parent) {
			parent.children = parent.children ?? [];
			parent.children.push(node3);
		}
	}
	for (const node2 of nodes2) {
		const parent = nodes1.find(item => item.id === node2.parentId);
		if (parent) {
			parent.children = parent.children ?? [];
			parent.children.push(node2);
		}
	}

	return nodes2;
};
