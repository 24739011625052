import { createRouter, RouterProvider } from '@tanstack/react-router';
import 'normalize.css';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { routeTree } from './routeTree.gen';
import features from './vendor/features.ts';

// Import CSS
import './assets/globals.css';
import './vendor/overwrites.css';

// Create a new router instance
export const router = createRouter({
	defaultPreload: features.network.preloadDelay === undefined ? false : 'intent',
	// We determine how fast the network is by how long the js took to download and start executing
	defaultPreloadDelay: features.network.preloadDelay,
	routeTree,
});

// Register the router instance for type safety
declare module '@tanstack/react-router' {
	interface Register {
		router: typeof router;
	}
}
// Render the app
const rootElement = document.querySelector('#root')!;
if (!rootElement.innerHTML) {
	ReactDOM.createRoot(rootElement).render(
		<React.StrictMode>
			<RouterProvider router={router} />
		</React.StrictMode>,
	);
}
