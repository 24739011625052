import { createFileRoute } from '@tanstack/react-router';

import { CartItem } from '@/components';

import { useOrderStore } from '@/stores';

const Cart = () => {
	const lines = useOrderStore(store => store.order.lines);
	return (
		<div>
			{lines.map(line => (
				<CartItem key={line.internalid} line={line} />
			))}
		</div>
	);
};

export const Route = createFileRoute('/cart/')({
	component: Cart,
});
