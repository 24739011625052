import { motion, SVGMotionProps } from 'framer-motion';
import React from 'react';

const Path = (properties: SVGMotionProps<SVGPathElement>) => (
	<motion.path
		fill="transparent"
		stroke="hsl(0, 0%, 18%)"
		strokeLinecap="round"
		strokeWidth="3"
		{...properties}
	/>
);

interface MenuToggleProperties {
	className?: string;
	id?: string;
	isOpen?: boolean;
	toggle: () => void;
}

export const MenuToggle: React.FC<MenuToggleProperties> = ({ className, id, isOpen, toggle }) => (
	<button className={className} id={id} onClick={toggle}>
		<svg>
			<Path
				animate={isOpen ? 'open' : 'closed'}
				variants={{
					closed: { d: 'M 2 2.5 L 20 2.5' },
					open: { d: 'M 3 16.5 L 17 2.5' },
				}}
			/>
			<Path
				animate={isOpen ? 'open' : 'closed'}
				d="M 2 9.423 L 20 9.423"
				transition={{ duration: 0.1 }}
				variants={{
					closed: { opacity: 1 },
					open: { opacity: 0 },
				}}
			/>
			<Path
				animate={isOpen ? 'open' : 'closed'}
				variants={{
					closed: { d: 'M 2 16.346 L 20 16.346' },
					open: { d: 'M 3 2.5 L 17 16.346' },
				}}
			/>
		</svg>
	</button>
);
