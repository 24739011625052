import { IconLoader2, IconSearch, IconX } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { Link, useNavigate, useSearch } from '@tanstack/react-router';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import { useCacheStore } from '@/stores';

import { getAllImages, getItems } from '@/utils/Item';

import { Item } from '@/customTypes/Item';

import styles from './SearchBar.module.css';

const MAX_ITEMS = 5;

const SearchBar: React.FC<{ className?: string }> = ({ className }) => {
	const navigate = useNavigate();
	const q = useSearch({
		select: ({ q }) => q,
		strict: false,
	}) as string;
	const [search, setSearch] = useState<string>(q ?? '');
	const [focused, setFocused] = useState(false);
	const [open, setOpen] = useState(false);
	const setSimplifiedItem = useCacheStore(store => store.setPdpSimplifiedItem);
	const { data, isLoading } = useQuery<{ items: Item[] }>({
		enabled: open && search.length > 2,
		queryFn: async ({ signal }) =>
			getItems(
				{
					fieldset: 'typeahead',
					limit: MAX_ITEMS,
					offset: 0,
					q: search,
					sort: 'relevance:desc',
				},
				signal,
			),
		queryKey: ['items-typeahead', search],
		staleTime: 1000 * 60 * 5,
	});

	useEffect(() => {
		if (search.length > 2 && focused) {
			const timeout = setTimeout(() => {
				setOpen(true);
			}, 200);

			return () => clearTimeout(timeout);
		}
		setOpen(false);
	}, [search, setOpen, focused]);

	// TODO make the input 100% width and use absolute positioning for the buttons
	const typeaheadOpen = open && !isLoading;
	return (
		<div className={`${styles.container} ${className ?? ''}`}>
			<div className={styles.searchContainer}>
				<input
					onBlur={event => {
						const isSearch = event.relatedTarget as HTMLElement | null;
						if (isSearch?.dataset.typeaheadItem) {
							return;
						}
						setOpen(false);
						setFocused(false);
					}}
					onFocus={() => {
						setFocused(true);
						setOpen(search.length > 2);
					}}
					onInput={event => setSearch(event.currentTarget.value)}
					onKeyDown={async event => {
						if (event.key === 'Enter' && search.length > 2) {
							event.preventDefault();
							setOpen(false);
							if (document.activeElement instanceof HTMLElement) document.activeElement.blur();
							await navigate({ search: { p: 1, q: search }, to: '/search' });
						}
					}}
					placeholder="Search for products..."
					value={search}
				/>
				{search.length > 0 ? (
					isLoading ? (
						<IconLoader2 className={`${styles.loader} loader`} />
					) : (
						<button
							className={styles.clearButton}
							onClick={() => {
								setSearch('');
								setOpen(false);
							}}
						>
							<IconX />
						</button>
					)
				) : null}
				<Link
					className={styles.searchButton}
					disabled={search.length < 3 || data?.items.length === 0}
					onClick={() => {
						setOpen(false);
					}}
					params={{}}
					search={{ p: 1, q: search }}
					to="/search"
				>
					<IconSearch size={18} />
				</Link>
			</div>
			<motion.div
				animate={{
					borderTopColor: typeaheadOpen ? 'lightgray' : 'white',
					height: typeaheadOpen ? 'auto' : 0,
				}}
				className={styles.typeaheadContainer}
				transition={{
					duration: 0.2,
					ease: 'easeInOut',
				}}
			>
				{data?.items.length === 0 ? (
					<span className={styles.noResultsText}>No results found</span>
				) : (
					data?.items.map(item => (
						<Link
							data-typeahead-item
							key={item.internalId}
							onClick={() => {
								setOpen(false);
								setSimplifiedItem(item);
							}}
							params={{ itemURL: item.url }}
							search={{}}
							to="/item/$itemURL"
						>
							<img
								alt={item.name}
								className={styles.suggestionImage}
								src={getAllImages(4, item)[0]}
							/>
							<label className={styles.suggestionText}>{item.name}</label>
						</Link>
					))
				)}
			</motion.div>
		</div>
	);
};

export default SearchBar;
