import { Link } from '@tanstack/react-router';
import React, { useEffect, useState } from 'react';

import ImageShower from '@/components/ImageShower/ImageShower.tsx';

import { Route } from '@/routes/item/$itemURL.tsx';

import { useCacheStore } from '@/stores';

import { SimplifiedItem } from '@/customTypes/Item';

import styles from './ItemComponent.module.css';

interface ItemComponentProperties {
	className?: string;
	item?: SimplifiedItem;
}

const ItemComponent: React.FC<ItemComponentProperties> = ({ className, item }) => {
	const [mouseInside, setMouseInside] = useState(false);
	const setSimplifiedItem = useCacheStore(store => store.setPdpSimplifiedItem);

	// Custom preload since preload doesn't work well with infinite scroll
	useEffect(() => {
		if (mouseInside && item) {
			const timeout = setTimeout(() => {
				Route.options.loader?.({ params: { itemURL: item.url } } as any);
			}, 1000);
			return () => clearTimeout(timeout);
		}
	}, [item, mouseInside]);

	return (
		<Link
			className={`${styles.container} ${className ?? ''}`}
			disabled={!item}
			draggable={false}
			onClick={() => {
				setSimplifiedItem(item!);
				console.log('saved simplified item');
			}}
			onDragStart={event => event.preventDefault()}
			onMouseEnter={() => setMouseInside(true)}
			onMouseLeave={() => setMouseInside(false)}
			params={{ itemURL: item?.url ?? '' }}
			preload={false}
			to="/item/$itemURL"
		>
			<ImageShower className={styles.image} item={item} mouseInside={mouseInside} />
			<span className={styles.name}>{item?.name ?? 'Loading...'}</span>
			<span className={styles.category}>Essentials</span>
			<label className={styles.price}>{item ? item.priceFormated : '$__.__'}</label>
		</Link>
	);
};

export default ItemComponent;
