import { motion } from 'framer-motion';
import React, { useEffect, useMemo, useState } from 'react';

import { Carousel, Image } from '@/components';

import { getAllImages } from '@/utils/Item.ts';

import { Item, SimplifiedItem } from '@/customTypes/Item';

import styles from './ImageGallery.module.css';

const ImageGallery: React.FC<{
	className?: string;
	item?: Item | SimplifiedItem;
}> = ({ className, item }) => {
	const [currentImage, setCurrentImage] = useState(0);
	const images = useMemo(() => getAllImages(7, item), [item]);
	if (item && !item.customFields) {
		console.log('no custom fields', item);
	}

	useEffect(() => {
		setCurrentImage(0);
	}, [images]);

	return (
		<div className={className}>
			<Carousel currentPosition={currentImage} setCurrentPosition={setCurrentImage}>
				{images.map((url, index) => (
					<Image
						alt={item?.name ?? ''}
						hashString={item?.customFields.blurhash_main ?? ''}
						key={index}
						src={url}
					/>
				))}
			</Carousel>
			<div className={styles.buttonContainer}>
				{images.map((image, index) => (
					<button
						className={styles.button}
						key={index}
						onClick={() => {
							setCurrentImage(index);
						}}
					>
						<img alt={item?.name} src={image} />
						{currentImage === index ? (
							<motion.div
								className={styles.box}
								layoutId="box"
								transition={{ duration: 0.4, type: 'spring' }}
							/>
						) : null}
					</button>
				))}
			</div>
		</div>
	);
};

export default ImageGallery;
