import { Link } from '@tanstack/react-router';
import { useShallow } from 'zustand/react/shallow';

import { CartItem } from '@/components';

import { useOrderStore } from '@/stores';

import styles from './MiniCart.module.css';

const CartComponent = () => {
	const { lines, summary } = useOrderStore(
		useShallow(store => ({
			lines: store.order.lines,
			summary: store.order.summary,
		})),
	);

	return (
		<div className={styles.container}>
			<div className={styles.subtotalContainer}>
				<label className={styles.subtotalText}>Subtotal: {summary.total_formatted}</label>
				<button>
					<Link className={styles.editCartText} params={{}} search={{}} to="/cart">
						Edit Cart
					</Link>
				</button>
			</div>
			<Link className={styles.checkoutButton} params={{}} search={{}} to="/">
				<span className={styles.checkoutText}>Continue to Checkout</span>
			</Link>
			<span className={styles.totalItems}>Total Items: {summary.itemcount}</span>
			<div className={styles.itemsContainer}>
				{summary.itemcount === 0 ? (
					<span>No items in the cart</span>
				) : (
					<div>
						{lines.map(line => (
							<CartItem key={line.internalid} line={line} />
						))}
					</div>
				)}
			</div>
		</div>
	);
};

export default CartComponent;
