import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { CatchBoundary, createRootRoute, Outlet } from '@tanstack/react-router';
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Header from '@/components/Header/Header';

import QueryProvider from '@/stores/QueryProvider.tsx';

import features from '@/vendor/features.ts';

const TanStackRouterDevtools =
	process.env.NODE_ENV === 'production'
		? () => null // Render nothing in production
		: React.lazy(() =>
				// Lazy load in development
				import('@tanstack/router-devtools').then(result => ({
					default: result.TanStackRouterDevtools,
					// For Embedded Mode
					// default: res.TanStackRouterDevtoolsPanel
				})),
			);

export const Route = createRootRoute({
	component: () => (
		<QueryProvider>
			<HelmetProvider>
				<Header />
				<CatchBoundary getResetKey={() => 'error'}>
					<Outlet />
				</CatchBoundary>
				<div
					style={{
						backgroundColor: 'green',
						display: 'none',
						height: '100px',
						width: '100%',
					}}
				>
					<span>Footer</span>
				</div>
				<TanStackRouterDevtools />
				<ReactQueryDevtools buttonPosition="bottom-right" />
				<Helmet>
					<title>{features.vendorName}</title>
				</Helmet>
			</HelmetProvider>
		</QueryProvider>
	),
	notFoundComponent: React.lazy(() => import('@/components/NotFound/NotFount.tsx')),
});
