import { createStore, setter } from '@/utils/store';

import { Item } from '@/customTypes/Item';

// Global state, meant to be used for interaction between components
const state = {
	cartOpen: false,
	menuOpen: false,
	searchCache: {} as Record<string, { cache: Record<number, Item[]>; total: number }>,
	typeaheadOpen: false,
};

export interface StateStore {
	setState: (profile: Partial<typeof state>) => void;
	state: typeof state;
}

export default createStore<StateStore>((set, get) => ({
	setState: data => {
		set({
			state: setter(data, get().state),
		});
	},
	state,
}));
