import { decodeBlurHash } from 'fast-blurhash';
import React, { useEffect, useMemo, useRef, useState } from 'react';

interface ImageProperties {
	alt: string;
	className?: string;
	hashString?: string;
	src: string;
	style?: React.CSSProperties;
}

const getStyle = (aspectRatio: number) => {
	if (aspectRatio < 1) {
		return {
			height: '100%',
		};
	}
	return {
		width: '100%',
	};
};

const Image: React.FC<ImageProperties> = ({ className, hashString, src, style }) => {
	const image = useRef<HTMLImageElement | null>(null);
	const [loaded, setLoaded] = useState(false);
	const canvas = useRef<HTMLCanvasElement | null>(null);

	const [hash, aspectRatio] = useMemo(() => {
		if (!hashString) return ['', 1];
		const match = /(.*)-([\d.]*)$/.exec(hashString);
		if (!match) return ['', 1];
		return [match[1], Number(match[2])];
	}, [hashString]);

	useEffect(() => {
		if (!canvas.current || !hash) return;

		const pixels = decodeBlurHash(hash, canvas.current.width, canvas.current.height);
		const context = canvas.current.getContext('2d')!;
		const imageData = context.createImageData(canvas.current.width, canvas.current.height);

		imageData.data.set(pixels);
		context.putImageData(imageData, 0, 0);
	}, [hash]);

	useEffect(() => {
		if (image.current) {
			if (image.current.complete) {
				setLoaded(true);
			} else {
				const current = image.current;
				if (!current) return;
				const listener = () => {
					setLoaded(true);
					current.removeEventListener('load', listener);
					current.removeEventListener('error', errorListener); // Cleanup the error listener as well
				};

				const errorListener = () => {
					setLoaded(false); // Or handle the error accordingly
					current.removeEventListener('error', errorListener);
					current.removeEventListener('load', listener); // Cleanup the load listener as well
				};

				current.addEventListener('load', listener);
				current.addEventListener('error', errorListener);
				return () => {
					current.removeEventListener('load', listener);
					current.removeEventListener('error', errorListener);
				};
			}
		}
	}, []);

	return (
		<>
			{hashString ? (
				<canvas
					className={`${className ?? ''}`}
					ref={canvas}
					style={{
						aspectRatio: `${aspectRatio} / 1`,
						display: loaded ? 'none' : 'block',
						...getStyle(aspectRatio),
						...style,
					}}
				/>
			) : null}
			<img
				alt={src}
				className={`${className ?? ''}`}
				draggable={false}
				ref={image}
				src={src}
				style={{
					aspectRatio: `${aspectRatio} / 1`,
					display: loaded ? 'block' : 'none',
					...getStyle(aspectRatio),
					...style,
				}}
			/>
		</>
	);
};

export default Image;
