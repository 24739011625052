import { IconHeart } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import {
	Button,
	Collapse,
	ImageGallery,
	ItemCarousel,
	LastViewed,
	NumberInput,
	Reviews,
	Stars,
} from '@/components';

import { queryClient, useCacheStore, useOrderStore, useProfileStore } from '@/stores';

import { getItems } from '@/utils/Item';

import { Item } from '@/customTypes/Item';

import styles from './$itemURL.module.css';

/**
 * Fetches related items based on the provided id.
 * @param id - The identifier for the item search.
 * @returns A promise that resolves to an array of related items.
 */
const getRelatedItems = async (id: number): Promise<Item[]> => {
	const search = await getItems({
		fieldset: 'correlateditems_details',
		id,
		limit: 10,
		offset: 0,
		sort: 'relevance:desc',
	});

	if (search.items.length > 0 && search.items[0].correlatedItemsDetail) {
		return search.items[0].correlatedItemsDetail as Item[];
	}
	throw new Error('No results found');
};

/**
 * Fetches item information based on the provided item URL.
 * @param itemURL - The URL of the item to fetch.
 * @returns A promise that resolves to the item information.
 */
const getItemInfo = async (itemURL: string): Promise<Item> => {
	const search = await getItems({
		fieldset: 'details',
		limit: 1,
		offset: 0,
		sort: 'relevance:desc',
		url: itemURL,
	});

	if (search.items.length > 0) {
		return search.items[0];
	}
	throw new Error('No results found');
};

const renderHtmlToText = (htmlString: string) => {
	const temporaryDiv = document.createElement('div');
	temporaryDiv.innerHTML = htmlString;
	return temporaryDiv.textContent;
};

const ProductDetailScreen: React.FC = () => {
	const addToLastViewed = useProfileStore(store => store.addToLastViewed);
	const { itemURL } = Route.useParams();
	const addLines = useOrderStore(store => store.addLines);
	const loading = useOrderStore(store => store.loading);
	const [quantity, setQuantity] = useState(1);
	const [onWishlist, setOnWishlist] = useState(false);
	const simplifiedItem = useCacheStore(store => store.pdpSimplifiedItem);
	const { data, error } = useQuery({
		queryFn: () => getItemInfo(itemURL),
		queryKey: ['item', itemURL],
		staleTime: 1000 * 60 * 5,
	});
	const descriptionString = useMemo(() => renderHtmlToText(data?.description ?? ''), [data]);

	useEffect(() => {
		if (!data && simplifiedItem) {
			console.log('Using simplified item');
		}
	}, [simplifiedItem, data]);

	useEffect(() => {
		if (data) {
			addToLastViewed(data!);
		}
	}, [addToLastViewed, data]);

	const { data: relatedData } = useQuery({
		enabled: Boolean(data?.internalId ?? simplifiedItem?.internalId),
		queryFn: () => getRelatedItems(data?.internalId ?? simplifiedItem!.internalId),
		queryKey: ['related-item', itemURL],
		staleTime: 1000 * 60 * 5,
	});

	if (error) {
		return <span>Invalid product ({error.message})</span>;
	}

	return (
		<div className={styles.container}>
			<Helmet>
				<title>{data?.name ?? simplifiedItem?.name ?? itemURL}</title>
				<meta content={descriptionString ?? 'Loading...'} name="description" />
			</Helmet>
			<div className={styles.topContainer}>
				<ImageGallery className={styles.imageContainer} item={data ?? simplifiedItem} />
				<div className={styles.detailSection}>
					<label className={styles.productTitle}>{data?.name ?? simplifiedItem?.name}</label>
					<label className={styles.sku}>
						<span className={styles.skuLabel}>SKU: </span>
						{data?.itemId ?? simplifiedItem?.itemId}
					</label>
					<div className={styles.reviewsContainer}>
						<Stars value={3} />
						<span>2 Reviews</span>
					</div>
					<span className={styles.productPrice}>
						{data?.priceFormated ?? simplifiedItem?.priceFormated}
					</span>

					<div className={styles.quantityContainer}>
						<span className={styles.quantityText}>Quantity:</span>
						<NumberInput min={1} setValue={setQuantity} value={quantity} />
					</div>
					<Button
						className={styles.addToCartButton}
						disabled={loading}
						onClick={() => {
							addLines([
								{
									item: {
										internalid: data!.internalId,
										itemtype: 'InvtPart',
									},
									quantity,
								},
							]).then(() => {
								setQuantity(1);
							});
						}}
					>
						Add to Cart
					</Button>
					<button
						className={`${styles.wishlistButton} ${onWishlist ? styles.active : ''}`}
						onClick={() => setOnWishlist(!onWishlist)}
					>
						<IconHeart />
						<label>{onWishlist ? 'Remove from Wishlist' : 'Add to Wishlist'}</label>
					</button>
					<Collapse defaultOpen title="Description">
						{/* eslint-disable-next-line react/no-danger */}
						<div dangerouslySetInnerHTML={{ __html: data?.description ?? '' }} />
					</Collapse>
				</div>
			</div>
			<span className={styles.freeShipping}>Free Shipping on orders over $50</span>
			<Reviews />
			<h3>Last Viewed</h3>
			<LastViewed />
			<h3>Related Items</h3>
			<ItemCarousel className={styles.relatedItems} items={relatedData} loadingAmmount={10} />
		</div>
	);
};

export const Route = createFileRoute('/item/$itemURL')({
	component: () => {
		console.log('ProductDetailScreen component');
		return <ProductDetailScreen />;
	},
	loader: async ({ params }) => {
		// We dont return this since we want to load the page even if this didnt finish loading
		// Because of simplifiedItem
		setTimeout(() => {
			queryClient.prefetchQuery({
				queryFn: () => getItemInfo(params.itemURL),
				queryKey: ['item', params.itemURL],
				staleTime: 1000 * 60,
			});
		}, 0);
	},
});
