import React, { ButtonHTMLAttributes, useState } from 'react';

import styles from './Button.module.css';

export type ButtonProperties = {
	type?: 'primary' | 'secondary';
} & ButtonHTMLAttributes<HTMLButtonElement>;

interface Ripple {
	key: number;
	x: number;
	y: number;
}

const RippleButton: React.FC<ButtonProperties> = ({ children, className, onClick, ...rest }) => {
	const [ripples, setRipples] = useState<Ripple[]>([]);

	return (
		<button
			{...rest}
			className={`${styles.primary} ${className ?? ''}`}
			onClick={event => {
				onClick?.(event);
				event.preventDefault();
				event.stopPropagation();
				const container = event.currentTarget.getBoundingClientRect();
				const x = event.clientX - container.left;
				const y = event.clientY - container.top;
				const newRipple = {
					key: Date.now(),
					x,
					y,
				};

				setRipples(previousRipples => [...previousRipples, newRipple]);
				// Remove ripple after animation
				setTimeout(() => {
					setRipples(previousRipples =>
						previousRipples.filter(ripple => ripple.key !== newRipple.key),
					);
				}, 300);
			}}
		>
			{ripples.map(ripple => (
				<span
					className={styles.ripple}
					key={ripple.key}
					style={{
						left: ripple.x - 150,
						top: ripple.y - 150,
					}}
				/>
			))}
			{typeof children === 'string' ? (
				<span style={{ display: 'inline-block' }}>{children}</span>
			) : (
				children
			)}
		</button>
	);
};

export default RippleButton;
