import { IconClock, IconTrash } from '@tabler/icons-react';
import { Link } from '@tanstack/react-router';
import React, { useEffect, useState } from 'react';

import ImageShower from '@/components/ImageShower/ImageShower.tsx';
import NumberInput from '@/components/NumberInput/NumberInput.tsx';
import TooltipButton from '@/components/TooltipButton/TooltipButton.tsx';

import { useCacheStore, useOrderStore } from '@/stores';

import { Line } from '@/customTypes/Order';

import styles from './CartItem.module.css';

const CartItem: React.FC<{
	line: Line;
}> = ({ line }) => {
	const setSimplifiedItem = useCacheStore(store => store.setPdpSimplifiedItem);
	const [mouseInside, mouseInsideSet] = React.useState(false);
	const loadingOrder = useOrderStore(store => store.loading);
	const updateLine = useOrderStore(store => store.updateLine);
	const removeLine = useOrderStore(store => store.removeLine);
	const [inputValue, setInputValue] = useState(line?.quantity ?? 1);

	useEffect(() => {
		if (!loadingOrder) {
			setInputValue(line!.quantity);
		}
	}, [line, loadingOrder]);

	useEffect(() => {
		if (line && !loadingOrder) {
			const timeout = setTimeout(() => {
				updateLine(line.internalid, line => {
					if (inputValue === line.quantity) {
						return line;
					}
					return { ...line, quantity: inputValue };
				});
			}, 300);

			return () => clearTimeout(timeout);
		}
	}, [inputValue, line, loadingOrder, updateLine]);

	return (
		<Link
			className={`${styles.container} ${mouseInside ? styles.hover : ''}`}
			disabled={loadingOrder}
			onClick={() => {
				setSimplifiedItem(line.item);
			}}
			onMouseEnter={() => mouseInsideSet(true)}
			onMouseLeave={() => mouseInsideSet(false)}
			params={{ itemURL: line?.item.url ?? '' }}
			search={{}}
			to="/item/$itemURL"
		>
			<ImageShower className={styles.image} item={line?.item} mouseInside={mouseInside} />
			<div className={styles.details}>
				<label>{line?.item.name}</label>
				<label className={styles.price}>{line?.item.priceFormated}</label>
				<div>
					<NumberInput
						disabled={loadingOrder}
						min={1}
						setValue={setInputValue}
						value={inputValue}
					/>
					<TooltipButton tooltip="Add to Wishlist">
						<IconClock />
					</TooltipButton>
					<TooltipButton
						className={styles.removeButton}
						disabled={loadingOrder}
						onClick={event => {
							removeLine(line.internalid);
							event.stopPropagation();
							event.preventDefault();
						}}
						tooltip="Remove from Cart"
					>
						<IconTrash />
					</TooltipButton>
				</div>
			</div>
		</Link>
	);
};

export default CartItem;
