import React, { useState } from 'react';

type CustomButtonProperties = {
	tooltip: string; // Tooltip content
	tooltipClassName?: string; // Optional class name for the tooltip
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const TooltipButton: React.FC<CustomButtonProperties> = ({
	children,
	onMouseEnter,
	onMouseLeave,
	style,
	tooltip,
	tooltipClassName,
	...rest
}) => {
	const [showTooltip, setShowTooltip] = useState(false);

	return (
		<button
			{...rest}
			onMouseEnter={event => {
				setShowTooltip(true);
				onMouseEnter?.(event);
			}}
			onMouseLeave={event => {
				setShowTooltip(false);
				onMouseLeave?.(event);
			}}
			style={{ position: 'relative', ...style }}
		>
			{children}
			{showTooltip ? (
				<span
					className={tooltipClassName}
					style={{
						backgroundColor: 'black',
						borderRadius: '4px',
						color: 'white',
						fontSize: '12px',
						left: '50%',
						padding: '3px',
						position: 'absolute',
						top: '-20px', // Position above the button
						transform: 'translateX(-50%)',
						whiteSpace: 'nowrap',
						zIndex: 99_999,
					}}
				>
					{tooltip}
				</span>
			) : null}
		</button>
	);
};

export default TooltipButton;
